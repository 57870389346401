<template>
  <div class="individual-student-messages-page">
    <v-row no-gutters class="mx-2 my-2" justify="center">
      <v-col md="5">
        <!-- <div v-if="isLoading">
          <v-skeleton-loader
            v-for="x in 3"
            :key="x"
            elevation="1"
            class="my-2 mx-2"
            type="list-item-avatar, list-item-three-line"
          ></v-skeleton-loader>
        </div>
        <div v-if="!posts.length && !isLoading" class="text-center mt-2">
          <h3>No posts found.</h3>
        </div> -->

        <div class="posts-container">
          <!-- <v-card
            v-for="post in posts"
            :key="post.id"
            :to="{ name: 'ViewBoardPost', params: { postId: post.id } }"
            class="my-2 post-card"
          >
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="post.user.image"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="subtitle-2">
                {{ post.user.full_name }}
              </v-list-item-content>
              <p class="my-0 caption grey--text">
                {{ moment(post.created).format("hh:mmA, Do MMM") }}
              </p>
            </v-list-item>
            <v-card-text class="py-1 pb-2">
              <div class="black--text subtitle-1 content-description">{{ post.description  | truncate(150, '...') }}</div>
              <v-img
                v-if="post.board_images.length"
                :src="post.board_images[0].image"
              ></v-img>
            </v-card-text>
            <v-card-actions
              class="pt-0 ml-1"
              v-if="post.board_images.length || post.board_documents.length || post.is_draft"
            >
              <v-chip small
                color="orange lighten-2 mr-1"
                v-if="post.is_draft && post.scheduled"
              >Scheduled</v-chip>
              <v-chip
                small
                color="light-blue lighten-4 mr-1"
                v-if="post.board_images.length && post.board_images.length > 1"
                >+{{ post.board_images.length - 1 }} Images</v-chip
              >
              <v-chip
                small
                color="teal lighten-4"
                v-if="post.board_documents.length"
                >{{ post.board_documents.length }} Document(s)</v-chip
              >
            </v-card-actions>
          </v-card> -->

        <intract-smart-list
          ref="student-messages-smart-list"
          :endpoint="studentMessagesEndpoint"
          paginated
          disable-search
        >
          <!-- :filter-fields="showFilterOptions ? filterFields : null " -->
          <template v-slot:list-item="{ item: post }">
          <v-card
              @click="
                  $router.push({
                    name: 'ViewBoardPost', 
                    params: { postId: post.id },
                  })
              "
              class="my-2 post-card"
          >
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="post.user.image"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="subtitle-2">
                {{ post.user.full_name }}
              </v-list-item-content>
              <p class="my-0 caption grey--text">
                {{ moment(post.created).format("hh:mmA, Do MMM") }}
              </p>
            </v-list-item>
            <v-card-text class="py-1 pb-2">
              <div class="black--text subtitle-1 content-description">{{ post.description  | truncate(150, '...') }}</div>
              <v-img
                v-if="post.board_images.length"
                :src="post.board_images[0].image"
              ></v-img>
            </v-card-text>
            <v-card-actions
              class="pt-0 ml-1"
              v-if="post.board_images.length || post.board_documents.length || post.is_draft"
            >
              <v-chip small
                color="orange lighten-2 mr-1"
                v-if="post.is_draft && post.scheduled"
              >Scheduled</v-chip>
              <v-chip
                small
                color="light-blue lighten-4 mr-1"
                v-if="post.board_images.length && post.board_images.length > 1"
                >+{{ post.board_images.length - 1 }} Images</v-chip
              >
              <v-chip
                small
                color="teal lighten-4"
                v-if="post.board_documents.length"
                >{{ post.board_documents.length }} Document(s)</v-chip
              >
            </v-card-actions>
          </v-card>

          </template>
        </intract-smart-list>

        </div>
      </v-col>
    </v-row>
    <!-- <infinite-loading
      key="individual-student-messages-infinite-loading"
      spinner="waveDots"
      :identifier="infiniteIdentifier"
      @infinite="getStudentMessages"
    >
      <div slot="no-results"></div>
    </infinite-loading> -->
    <v-fab-transition v-if="currentUser.is_faculty && !isLoading">
      <v-btn
        color="primary"
        @click="messageCreateSheet.visible = true"
        dark
        bottom
        right
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <intract-create-edit-sheet
      persistent
      v-if="!currentUser.is_student"
      title="Send Message"
      description="This will notify the student you are sending this message to"
      :visible="messageCreateSheet.visible"
      @close="messageCreateSheet.visible = false"
      :fields="formFields"
      enable-media
      :endpoint="endpoints.boardPosts"
      :edit-id="messageCreateSheet.editId"
      :data-object="messageCreateSheet.obj"
      :create-success-message="messageCreateSheet.createSuccessMessage"
      @objectCreated="messageSent"
      @updateObject="(obj) => (messageCreateSheet.obj = obj)"
    >
      <!-- schedule for later button slot -->
      <template v-slot:after-submit-button v-if="!messageCreateSheet.obj.scheduled">
        <v-dialog
            v-model="scheduleDialog.visible"
            width="500"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue"
                outlined
                block
                dark
                v-bind="attrs"
                v-on="on"
              >
                Schedule for Later
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Schedule for later
               <v-btn icon absolute top right @click="scheduleDialog.visible=false; scheduleDialog.datetime=null">
                 <v-icon>mdi-close</v-icon>
               </v-btn>
              </v-card-title>

              <v-card-text>
                <v-text-field class="mt-4 mb-0" hide-details="auto" dense outlined type="datetime-local" label="Schedule Date & Time" v-model="scheduleDialog.datetime">
                </v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  block
                  :disabled="!scheduleDialog.datetime"
                  @click="messageCreateSheet.obj.scheduled = scheduleDialog.datetime; scheduleDialog.visible = false; scheduleDialog.datetime = null"
                >
                  Schedule 
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog> 
      </template>
      <!-- scheduled for information -->
      <template v-slot:custom-field--scheduled="{ obj }">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              Scheduled for <strong class="orange--text">{{ moment(obj.scheduled).format('LLL') }}</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              Will be published at the specified time.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="obj.scheduled = null">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
    </intract-create-edit-sheet>
  </div>
</template>
<script>
import IntractSmartList from "@components/generics/IntractSmartList";
import { mapActions, mapGetters } from "vuex";
// import api from "@api/index";
import endpoints from "@api/repository";
import Helper from "@utils/misc";
import moment from "moment";
import Mixins from "@utils/mixins";
// import InfiniteLoading from "vue-infinite-loading";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
// import MugenScroll from "vue-mugen-scroll";
import EventBus from "@utils/event_bus";

export default {
  name: "IndividualStudentMessages",
  mixins: [Mixins.handleAppBarTitle],
  props: {
    student: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      endpoints: endpoints,
      moment: moment,
      // moreLoading: false,
      studentId: null,
      scheduleDialog: {
        visible: false,
        datetime: null,
      },
      messageCreateSheet: {
        visible: false,
        createSuccessMessage: "Message sent successfully!",
        editId: null,
        obj: {
          is_alert: true,
          description: "",
          institution: null,
          recipients: [],
          scheduled: null,
        },
      },
      pagination: {
        nextUrl: null,
        count: null,
      },
      // firstLoad: true,
      // posts: [],
      // showCreateBoardPostSheet: false,
      // infiniteIdentifier: new Date().toString(),
    };
  },
  components: {
    // MugenScroll,
    // InfiniteLoading,
    IntractSmartList,
    IntractCreateEditSheet,
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "currentUser",
      "accessToken",
      "currentInstitution",
    ]),

    studentMessagesEndpoint(){
      var endpoint_url = Helper.addUrlParams(endpoints.boardPosts, [
            "is_alert=true",
            "recipients=" + this.studentId,
          ])

      if(this.currentUser.is_student) endpoint_url = Helper.addUrlParams(endpoint_url, 'is_draft=false'); //hide drafts from students

      return endpoint_url;
    },

    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
    formFields() {
      return {
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        allow_replies: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Allow Replies",
          helper: "Will allow students to add replies to this notice. Replies will also be visible to other students.",
          required: false,
          md: 12,
        },
        scheduled: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          hide: this.messageCreateSheet.obj.scheduled ? false: true,
        },
        board_images: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: true,
          label: "Images",
          helper: "JPG, PNG, GIF, etc",
        },
        board_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
    appBarTitle() {
      if (this.student) return this.student.full_name;
      else return "Student Messages";
    },
  },
  methods: {
    ...mapActions(["showSnackbar", "setLoading"]),

    async messageSent(data) {
      this.onRefresh();
      this.$router.push({ name: "ViewBoardPost", params: { postId: data.id } });
    },

    // async getStudentMessages(state) {
    //   if (this.studentId == null || this.isLoading) return;
    //   if (!state) {
    //     //refresh
    //     this.pagination.nextUrl = this.pagination.count = null;
    //     this.posts = [];
    //     this.firstLoad = true;

    //     // this.infiniteIdentifier = new Date().toString();
    //     this.infiniteIdentifier = new Date().toString();
    //     return;
    //     // return;
    //   }
    //   // if (this.isMoreLoading) return;
    //   // if (loadMore && !this.pagination.nextUrl) return;
    //   var endpoint_url = Helper.addUrlParams(endpoints.boardPosts, [
    //         "is_alert=true",
    //         "recipients=" + this.studentId,
    //       ])

    //   if(this.currentUser.is_student) endpoint_url = Helper.addUrlParams(endpoint_url, 'is_draft=false'); //hide drafts from students

    //   var url = this.firstLoad
    //     ? endpoint_url
    //     : this.pagination.nextUrl;
    //   var response = await api.call(this.essentials, url);
    //   if (response) {
    //     this.firstLoad = false;
    //     this.posts.push(...response.results);
    //     this.pagination.nextUrl = response.next;
    //     this.pagination.count = response.count;
    //     if (state) {
    //       if (this.posts.length == response.count) state.complete();
    //       else state.loaded();
    //     }
    //   }
    // },

    async handleApiError(err) {
      this.moreLoading = false;
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.showSnackbar({
          title: "Something went wrong",
          type: "error",
        });
      }
    },

    async onRefresh(){
      if (this.$refs["student-messages-smart-list"])
          this.$refs["student-messages-smart-list"].getItems();
    },

    async initListeners() {
      EventBus.$on(
        "view_board_post__post_deleted",
        // (id) => (this.posts = this.posts.filter((p) => p.id != id))
        (id) => (this.$refs["student-messages-smart-list"].removeItem(id))
      );

      EventBus.$on("view_board_post__post_updated", () => {
        // var listPost = this.posts.find((p) => p.id == post.id);
        // if (listPost) {
        //   listPost.description = post.description;
        //   listPost.board_images = post.board_images;
        //   listPost.board_documents = post.board_documents;
        // }
        this.onRefresh();
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != "ViewBoardPost") {
      console.log("destroying");
      // this.$destroy();
      EventBus.$emit("keep_alive__destroy_view", "IndividualStudentMessages");
    }
    next();
  },
  created() {
    this.onRefresh();
    this.studentId = this.currentUser.is_student
      ? this.currentUser.id
      : this.$route.params.studentId; // current user's id is used if used as component (student)
    if (!this.currentUser.is_student) {
      this.messageCreateSheet.obj.recipients.push(this.currentUser.id);
      this.messageCreateSheet.obj.recipients.push(this.studentId);
      this.messageCreateSheet.obj.institution = this.currentInstitution.id;
      this.messageCreateSheet.obj.user = this.currentUser.id;
    }

    this.initListeners();
  },
  beforeDestroy() {
    Helper.destroyPullRefresh();
  },
};
</script>
<style scoped>
.fab-transition-enter {
  transition-delay: 2s;
}
</style>